@font-face {
  font-family: "iconfont"; /* Project id 2720060 */
  src: url('./font/iconfont.woff2') format('woff2'),
       url('./font/iconfont.woff') format('woff'),
       url('./font/iconfont.ttf') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-shangxiaqiehuan:before {
  content: "\e7eb";
}

.icon-cuowu:before {
  content: "\e603";
}

.icon-bianji:before {
  content: "\e630";
}

.icon-right-arr:before {
  content: "\e629";
}

.icon-left-arr:before {
  content: "\e602";
}

.icon-transfers:before {
  content: "\e67f";
}

.icon-link:before {
  content: "\e617";
}

.icon-Group-:before {
  content: "\e68b";
}

.icon-wenhao:before {
  content: "\e64e";
}

@primary-color: rgba(250,226,71,1);@btn-primary-color: rgba(0,0,0,1);@text-color: rgba(255,255,255,0.65);@main-white: rgba(255,255,255,0.85);@main-black: rgba(0,0,0,1);@main-border-color: rgba(255,255,255,0.15);@main-yellow: rgba(250,226,71,1);@main-red: rgba(234,68,107,1);@main-green: rgba(0,196,154,1);@deep-green: rgba(0, 196, 154, 100);@main-background: rgba(20,11,50,1);@main-blue: rgba(32, 27, 72, 100);@main-block: rgba(32,27,72,1);@strong-text: rgba(255, 255, 255, 85);@support-text: rgba(255, 255, 255, 45);@disable-text: rgba(255, 255, 255, 30);