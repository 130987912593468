@import './iconfont.css';

.derify-iconfont {
  display: inline-block;
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  line-height: 1;
  margin:0 4px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:before {
    display: block;
    font-family: 'iconfont' !important;
  }
}

@primary-color: rgba(250,226,71,1);@btn-primary-color: rgba(0,0,0,1);@text-color: rgba(255,255,255,0.65);@main-white: rgba(255,255,255,0.85);@main-black: rgba(0,0,0,1);@main-border-color: rgba(255,255,255,0.15);@main-yellow: rgba(250,226,71,1);@main-red: rgba(234,68,107,1);@main-green: rgba(0,196,154,1);@deep-green: rgba(0, 196, 154, 100);@main-background: rgba(20,11,50,1);@main-blue: rgba(32, 27, 72, 100);@main-block: rgba(32,27,72,1);@strong-text: rgba(255, 255, 255, 85);@support-text: rgba(255, 255, 255, 45);@disable-text: rgba(255, 255, 255, 30);