@import "~antd/dist/antd.less";
@import "./assets/styles/reset.less";

//common class
.main-block {
  background-color: @main-block;
  padding: 16px 24px;
  border-radius: 16px;
}

//color text
.main-color {
  color: @primary-color;
}

.main-red {
  color: @main-red;
}

.main-green {
  color: @main-green;
}

.main-white {
  color: @main-white;
}
.ant-modal-body {
  padding-top: 0;
  font-size: 12px;
}
.ant-spin-blur::after {
  opacity: 0;
}

.login-modal > div {
  top: calc(50% - 180px);
}

.login-modal {
  .ant-modal-title {
    text-align: center;
  }
  .ant-modal-content {
    background-color: #433c80;
    .ant-modal-header {
      background-color: #433c80;
    }
  }
}

.main-no-scroll {
  height: 100%;
  overflow: hidden;
}

//margin
@margin: {
  s: 8px;
  m: 12px;
  l: 24px;
  max: 48px;
};

each(@margin, {
    .margin-b-@{key} {
      margin-bottom: @value
    }
  }

);

//other
.derify-pointer {
  cursor: pointer;
}

@primary-color: rgba(250,226,71,1);@btn-primary-color: rgba(0,0,0,1);@text-color: rgba(255,255,255,0.65);@main-white: rgba(255,255,255,0.85);@main-black: rgba(0,0,0,1);@main-border-color: rgba(255,255,255,0.15);@main-yellow: rgba(250,226,71,1);@main-red: rgba(234,68,107,1);@main-green: rgba(0,196,154,1);@deep-green: rgba(0, 196, 154, 100);@main-background: rgba(20,11,50,1);@main-blue: rgba(32, 27, 72, 100);@main-block: rgba(32,27,72,1);@strong-text: rgba(255, 255, 255, 85);@support-text: rgba(255, 255, 255, 45);@disable-text: rgba(255, 255, 255, 30);